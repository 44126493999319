<template>
  <v-container class="pa-0">
    <v-app-bar
      v-if="!smallScreen"
      class="rounded-t-lg"
      dense
      color="grey lighten-2"
      elevation="0">
      <v-toolbar-title>
        <v-icon class="mr-2">
          mdi-message
        </v-icon>
        {{ i18n("messages") }}
        <v-chip
          v-if="unreadMessage > 0"
          class="px-2 mb-5"
          color="error"
          x-small>
          {{ unreadMessage }}
        </v-chip>
      </v-toolbar-title>
    </v-app-bar>
    <v-card
      v-if="!smallScreen"
      tile
      outlined
      elevation="0"
      id="messages"
      height="70vh"
      class="overflow-y-auto overflow-x-hidden">
      <Message
        v-for="(message, index) in sortedMessages"
        :key="index"
        :message="message"/>
    </v-card>
    <v-card
      v-if="smallScreen"
      elevation="0">
      <Message
        v-for="(message, index) in sortedMessages"
        :key="index"
        :message="message"/>
    </v-card>
    <Compose :kase="kase" @sent="delayScrollToBottom()" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Message from './Messages/Message'
import Compose from './Messages/Actions/Compose'

export default {
  name: 'Chat',
  props: ['unreadMessage', 'smallScreen'],
  components: {
    Message,
    Compose
  },
  computed: {
    ...mapGetters(['kase', 'messages']),
    sortedMessages () {
      const sortedMessages = [...this.messages].sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at)
      })
      return sortedMessages
    }
  },
  methods: {
    delayScrollToBottom () {
      setTimeout(this.scrollToTarget, 200)
    },
    scrollToTarget () {
      if (this.smallScreen) {
        window.scrollTo(0, (document.documentElement.scrollHeight))
      } else {
        const container = this.$el.querySelector('#messages')
        if (!container) { return }
        container.scrollTop = container.scrollHeight
      }
    }
  },
  mounted () {
    this.delayScrollToBottom()
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
</style>
