<template>
  <span>
    <v-btn color="primary" @click="startLogin" v-if="!isLoggedIn">
      <v-icon class="mr-2">mdi-account</v-icon>
      {{ i18n("login") }}
    </v-btn>
    <v-btn color="error" @click="startLogout" v-if="isLoggedIn">
      <v-icon class="mr-2">mdi-logout</v-icon>
      {{ i18n("logout") }}
    </v-btn>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import apiOptions from '@/api/options'

export default {
  name: 'LoginButton',
  computed: {
    ...mapGetters(['accessToken', 'isLoggedIn'])
  },
  methods: {
    startLogin () {
      window.location = `${apiOptions.authURL}?client_id=${apiOptions.clientID}&response_type=token&redirect_uri=${encodeURI(apiOptions.redirectURI)}`
    },
    startLogout () {
      window.location = apiOptions.logoutURL
    }
  }
}
</script>
