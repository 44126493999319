<template>
  <v-navigation-drawer v-model="drawer" dark app clipped color="secondary">
    <v-container class="text-center" v-if="loading">
      <v-progress-circular color="white" size="56" indeterminate />
    </v-container>
    <v-container v-if="!loading && error">
      <v-alert color="error">
        <p>
          <v-icon>mdi-alert</v-icon>
        </p>
        <p>
          {{ i18n("notAuthorized") }}
        </p>
      </v-alert>
    </v-container>
    <v-container class="pb-0" v-if="!loading && !error">
      <v-text-field
        v-model="filter"
        hide-details
        outlined
        dense
        prepend-icon="mdi-magnify"
        placeholder="Filter Jobs"
        clearable />
    </v-container>
    <v-list v-if="!loading">
      <v-list-item
        v-for="(job, index) in filteredCases"
        :key="index"
        @click="openCase(job)">
        <v-list-item-content>
          <v-row>
            <v-col>
              <v-chip
                label
                class="rounded-lg"
                :color="job.outsource_key === outsourceKey ? 'primary' : ''"
                :outlined="job.outsource_key !== outsourceKey">
                <v-icon small class="mr-1" v-if="job.outsource_key !== outsourceKey">mdi-email</v-icon>
                <v-icon small class="mr-1" v-if="job.outsource_key === outsourceKey">mdi-email-open</v-icon>
                {{ job.case_mkey }}
              </v-chip>
            </v-col>
            <v-col class="text-right">
              <v-chip x-small :color="jobStatusColor(job)">
                {{ jobStatus(job) }}
              </v-chip>
              <br/>
              <small>{{ job.expected_return | luxonRelative }}</small>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CaseInboxDrawer',
  props: ['value'],
  data () {
    return {
      error: false,
      loading: true,
      filter: '',
      cases: []
    }
  },
  computed: {
    ...mapGetters(['outsourceKey']),
    drawer: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    filteredCases () {
      if (!this.filter || this.filter.length === 0) { return this.cases }
      return this.cases.filter(kase => kase.case_mkey.toLowerCase().includes(this.filter.toLowerCase()))
    }
  },
  methods: {
    ...mapActions(['setOutsourceKey', 'triggerReload', 'setCurrentView']),
    async fetchInbox () {
      this.loading = true
      this.$axios.get('/partners/').then((response) => {
        this.loading = false
        this.cases = response.data
      }).catch(() => {
        this.loading = false
        this.error = true
      })
    },
    openCase (job) {
      this.setOutsourceKey(job.outsource_key)
      this.triggerReload()
      this.setCurrentView('job')
      this.$emit('input', false)
    },
    jobStatus (job) {
      if (new Date(job.expected_return) < Date.now()) { return this.i18n('jobOverdue') }
      return this.i18n(this.toCamelCase(job.status))
    },
    jobStatusColor (job) {
      if (new Date(job.expected_return) < Date.now()) { return 'error' }
      if (job.status === 'IN_PROGRESS') { return 'success' }
      return 'warning'
    },
    toCamelCase (string) {
      return string.toLowerCase().replace(/[^a-zA-Z]+(.)/g, (text) => text.toUpperCase()).replaceAll('_', '')
    }
  },
  mounted () {
    this.fetchInbox()
    setInterval(this.fetchInbox, 30000)
  }
}
</script>
