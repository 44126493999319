<template>
  <v-row class="py-2"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 2000,
    }">
    <v-col sm="12" :offset-lg="columnOffset" lg="6" :align="align">
      <v-card elevation="0">
        <v-card-text class="py-0 my-0">
          <v-chip
            :color="bubbleColor"
            class="pa-2 text-left rounded-lg"
            style="height: auto;">
            <span style="white-space: pre-wrap">
              <span>{{ message.note }}</span>
            </span>
          </v-chip>
        </v-card-text>
        <v-card-actions class="pt-1 mt-0 px-5">
          <v-spacer v-if="align === 'right'" />
          <Attachments :message="message" class="mr-5" />
        </v-card-actions>
      </v-card>
      <div class="mx-5">
        <small>
          <span v-if="message.author_reference">
            <v-icon small>
              mdi-account
            </v-icon>
            {{ message.author_reference }}
            -
          </span>
          {{ message.created_at | luxon({ output: "tz_med" }) }}
        </small>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Attachments from './Actions/Attachments'

export default {
  name: 'Message',
  props: ['message'],
  components: {
    Attachments
  },
  computed: {
    ...mapGetters(['kase']),
    align () {
      if (this.message.direction === 'OUTBOUND') {
        return 'left'
      }
      return 'right'
    },
    columnOffset () {
      if (this.message.direction === 'OUTBOUND') {
        return 0
      }
      return 6
    },
    bubbleColor () {
      if (this.message.direction === 'INBOUND') {
        return 'success'
      }
      return 'null'
    }
  },
  methods: {
    ...mapActions(['setMessageSeen']),
    async visibilityChanged (isVisible, entry) {
      if (!isVisible || this.message.message_seen) { return }
      this.$axios.patch(`/api/v2/outsource/${this.kase.outsource_key}/message/${this.message.uid}/`, {
        action: 'read'
      })
      this.setMessageSeen(this.message.uid)
    }
  }
}
</script>
