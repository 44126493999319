<template>
  <v-btn
    x-large
    class="rounded-lg"
    color="primary"
    width="100%"
    v-if="kase.status === 'PENDING'"
    @click="triggerStartJob()">
    <v-icon class="mr-2">
      mdi-progress-wrench
    </v-icon>
    {{ i18n("startJob") }}
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Start',
  computed: {
    ...mapGetters(['kase'])
  },
  methods: {
    ...mapActions(['triggerReload']),
    async triggerStartJob () {
      this.$axios.post(`/api/v2/outsource/${this.kase.outsource_key}/`, {
        action: 'start_job'
      }).then(() => {
        this.triggerReload()
      })
    }
  }
}
</script>
