<template>
  <v-dialog v-model="viewFileModal" fullscreen>
    <v-card v-if="value" tile>
      <v-toolbar color="primary">
        <v-btn icon dark @click="viewFileModal = false">
        <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          v-if="filePath"
          outlined
          class="rounded-lg"
          @click="downloadFile()">
          <v-icon class="mr-2">mdi-download</v-icon>
          {{ i18n("download") }}
        </v-btn>
      </v-toolbar>
      <v-row v-if="!filePath">
        <v-col class="text-center">
          <v-progress-circular
            indeterminate
            size="128"
            color="primary"
            class="my-5">
            {{ i18n("loadingFile") }}
          </v-progress-circular>
        </v-col>
      </v-row>
      <v-container fluid v-if="filePath">
        <v-img contain v-if="fileType === 'image'" :src="`${filePath}`" height="80vh" />
        <div v-if="fileType === 'html'" class="iframe-container">
          <iframe v-if="!smallScreen" :src="`${filePath}`"/>
        </div>
        <ModelViewer
          class="ma-0 pa-0"
          :background-color="backgroundColor"
          :file-path="filePath"
          v-if="fileType === 'model' && canvasReady"
          :width="canvasWidth"
          :height="canvasHeight" />
        <v-row v-if="cannotView">
          <v-col class="text-center my-5">
            <p>
              {{ i18n("cantPreviewFile") }}
            </p>
            <p class="mb-5">
              {{ i18n("downloadToDevice") }}
            </p>
            <v-btn
              dark
              outlined
              class="mt-5 rounded-lg"
              @click="downloadFile()">
              <v-icon class="mr-2">mdi-download</v-icon>
              {{ i18n("download") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="htmlSmallScreenView">
          <v-col class="text-center my-5">
            <p class="mb-5">
              {{ i18n("viewDesignText") }}
            </p>
            <v-btn
              dark
              outlined
              class="mt-5"
              @click="downloadFile()">
              <v-icon class="mr-2">mdi-open-in-new</v-icon>
              {{ i18n("openFile") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModelViewer from '@/shared/ModelViewer'

export default {
  name: 'FileViewer',
  props: ['value', 'type'],
  components: {
    ModelViewer
  },
  data () {
    return {
      filePath: null,
      fileName: null,
      cannotView: false,
      htmlSmallScreenView: false,
      canvasReady: false,
      canvasWidth: null,
      canvasHeight: null
    }
  },
  watch: {
    value (file) {
      if (!file) { return }
      this.fetchFileURI()
    }
  },
  computed: {
    ...mapGetters(['kase']),
    fileType () {
      const fileName = this.getFileName()
      if (!fileName) { return }
      const fileExt = fileName.toLowerCase().split('.').pop()
      switch (fileExt) {
        case 'html':
          return 'html'
        case 'jpg':
        case 'jpeg':
        case 'png':
          return 'image'
        case 'ply':
        case 'stl':
          return 'model'
        default:
          return 'download'
      }
    },
    viewFileModal: {
      get () {
        if (this.value) { return true }
        return false
      },
      set (value) {
        this.$emit('input', value)
        this.filePath = null
        this.fileName = null
        this.cannotView = false
        this.htmlSmallScreenView = false
      }
    },
    smallScreen () {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
    }
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading'
    ]),
    async fetchFileURI () {
      this.startLoading()
      this.$axios.get(`/api/v2/outsource/${this.kase.outsource_key}/file/${this.type}/${this.value}/`).then((response) => {
        this.filePath = response.data.url
        this.fileName = response.data.file_name || response.data.filename
        if (this.fileType === 'download' && !this.smallScreen) {
          this.downloadFile()
        } else if (this.fileType === 'download' && this.smallScreen) {
          this.cannotView = true
        } else if (this.fileType === 'html' && this.smallScreen) {
          this.htmlSmallScreenView = true
        }
        this.stopLoading()
      })
    },
    downloadFile () {
      window.open(this.filePath, '_blank')
      this.$emit('input', false)
    },
    setCanvasHeight () {
      this.canvasHeight = window.innerHeight - (this.smallScreen ? 90 : 100)
    },
    setCanvasWidth () {
      this.canvasWidth = window.innerWidth - (this.smallScreen ? 25 : 30)
    },
    getFileName () {
      let fileName = null
      if (this.fileName) {
        fileName = this.fileName
      }
      if (this.value && this.value.file_name) {
        fileName = this.value.file_name
      }
      return fileName
    }
  },
  mounted () {
    setTimeout(() => {
      this.setCanvasHeight()
      this.setCanvasWidth()
      this.canvasReady = true
    }, 100)
  }
}
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
   border: 0;
   height: 80%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}
</style>
