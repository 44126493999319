import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: null,
    outsourceKey: null,
    currentView: 'job',
    blockLoading: false,
    error: false,
    reloadTrigger: false,
    loading: true,
    currentTab: 'overview',
    kase: null,
    messages: [],
    locale: 'en',
    selectedItem: null,
    languages: [
      { text: 'English', value: 'en' },
      { text: '简体中文', value: 'zh' }
    ]
  },
  getters: {
    accessToken: state => {
      return state.accessToken
    },
    isLoggedIn: state => {
      return !!state.accessToken
    },
    outsourceKey: state => {
      return state.outsourceKey
    },
    currentView: state => {
      return state.currentView
    },
    isLoading: state => {
      return state.loading
    },
    isBlockLoading: state => {
      return state.blockLoading
    },
    isError: state => {
      return state.error
    },
    currentTab: state => {
      return state.currentTab
    },
    reloadTrigger: state => {
      return state.reloadTrigger
    },
    kase: state => {
      return state.kase
    },
    locale: state => {
      return state.locale
    },
    selectedItem: state => {
      return state.languages.map(language => language.value).indexOf(state.locale)
    },
    languages: state => {
      return state.languages
    },
    messages: state => {
      return state.messages
    }
  },
  mutations: {
    SET_ACCESS_TOKEN (state, payload) {
      state.accessToken = payload
    },
    SET_OUTSOURCE_KEY (state, payload) {
      state.outsourceKey = payload
    },
    SET_CURRENT_VIEW (state, payload) {
      state.currentView = payload
    },
    SET_LOADING (state, payload) {
      state.loading = payload
    },
    SET_BLOCK_LOADING (state, payload) {
      state.blockLoading = payload
    },
    SET_ERROR (state, payload) {
      state.error = payload
    },
    SET_CURRENT_TAB (state, payload) {
      state.currentTab = payload
    },
    SET_KASE (state, payload) {
      state.kase = payload
    },
    SET_RELOAD_TRIGGER (state, payload) {
      state.reloadTrigger = payload
    },
    SET_MESSAGES (state, payload) {
      state.messages = payload
    },
    SET_MESSAGE_SEEN (state, uid) {
      const message = state.kase.messages.find(message => message.uid === uid)
      if (!message) { return }
      message.message_seen = true
    },
    SET_LOCALE (state) {
      if (!localStorage.locale) {
        const browserLocale = navigator.languages &&
          navigator.languages.length
          ? navigator.languages[0]
          : navigator.language
        if (!state.languages.map(language => language.value).includes(browserLocale.split('-')[0])) {
          localStorage.locale = state.locale
          return
        }
        localStorage.locale = browserLocale.split('-')[0] || 'en'
      }
      state.locale = localStorage.locale
    },
    SET_LOCAL_STORAGE (state, index) {
      localStorage.locale = state.languages.map(language => language.value)[index] || 'en'
    }
  },
  actions: {
    setAccessToken ({ commit }, token) {
      commit('SET_ACCESS_TOKEN', token)
      commit('SET_CURRENT_VIEW', 'caseInbox')
    },
    setOutsourceKey ({ commit }, key) {
      commit('SET_OUTSOURCE_KEY', key)
    },
    setCurrentView ({ commit }, view) {
      commit('SET_CURRENT_VIEW', view)
    },
    startLoading ({ commit }) {
      commit('SET_LOADING', true)
    },
    stopLoading ({ commit }) {
      commit('SET_LOADING', false)
    },
    startBlockLoading ({ commit }) {
      commit('SET_BLOCK_LOADING', true)
    },
    stopBlockLoading ({ commit }) {
      commit('SET_BLOCK_LOADING', false)
    },
    setError ({ commit }) {
      commit('SET_ERROR', true)
    },
    setCurrentTab ({ commit }, tab) {
      commit('SET_CURRENT_TAB', tab)
    },
    triggerReload ({ commit }) {
      commit('SET_RELOAD_TRIGGER', true)
    },
    reloadComplete ({ commit }) {
      commit('SET_RELOAD_TRIGGER', false)
    },
    setKase ({ commit }, kase) {
      commit('SET_KASE', kase)
    },
    setMessageSeen ({ commit }, messageUID) {
      commit('SET_MESSAGE_SEEN', messageUID)
    },
    setLocale ({ commit }) {
      commit('SET_LOCALE')
    },
    updateLocalStorage ({ commit }, index) {
      commit('SET_LOCAL_STORAGE', index)
    },
    setMessages ({ commit }, messages) {
      commit('SET_MESSAGES', messages)
    }
  },
  modules: {
  }
})
