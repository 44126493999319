<template>
  <v-card
    outlined
    rounded="lg"
    v-if="!kase.upload_not_required">
    <v-app-bar
      dense
      color="grey lighten-2"
      elevation="0"
      class="mb-2">
      <v-toolbar-title>
        <v-icon class="mr-2">
          mdi-cloud-check
        </v-icon>
        {{ i18n("completedDigitalWorkSection") }}
      </v-toolbar-title>
      <v-spacer />
      <v-chip color="warning" class="rounded-lg">{{ i18n("digital") }}</v-chip>
    </v-app-bar>
    <v-card-text>
      <v-file-input
        outlined
        dense
        v-model="upload"
        :label="i18n('uploadFileLabel')" />
      <v-row>
        <v-spacer />
        <v-col :cols="smallScreen ? 12 : 6">
          <v-btn
            class="rounded-lg"
            color="success"
            width="100%"
            :disabled="uploading"
            @click="uploadFile()">
            <v-progress-circular
              class="mr-2"
              v-if="uploading"
              size="24"
              indeterminate />
            <v-icon
              class="mr-2"
              v-if="!uploading">
              mdi-cloud-upload
            </v-icon>
            {{ uploading ? i18n("uploading") : i18n("upload") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DigitalJob',
  props: ['smallScreen'],
  data () {
    return {
      upload: null,
      uploading: false
    }
  },
  computed: {
    ...mapGetters(['kase'])
  },
  methods: {
    ...mapActions(['triggerReload']),
    async uploadFile () {
      this.uploading = true
      const formData = new FormData()
      formData.append('action', 'complete_job')
      formData.append('file', this.upload, this.upload.name)
      this.$axios.post(`/api/v2/outsource/${this.kase.outsource_key}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.uploading = false
        this.upload = null
        this.triggerReload()
      })
    }
  }
}
</script>
