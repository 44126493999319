<template>
  <v-card outlined class="text-center rounded-lg">
    <v-progress-linear
      height="48"
      :value="calculateProgress.percentage"
      :color="color">
      <strong v-if="!this.kase.overdue_sent_at">
        {{ i18n("timeUsed") }} {{ calculateProgress.percentage }}%
        ({{ calculateProgress.hours }} {{ i18n("hoursLeft") }})
      </strong>
      <strong v-if="this.kase.overdue_sent_at">
        {{ i18n("jobOverdue") }}
      </strong>
    </v-progress-linear>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'ProgressBar',
  computed: {
    ...mapGetters(['kase']),
    color () {
      if (!this.calculateProgress) { return }
      if (this.calculateProgress.percentage > 80) {
        return 'error'
      }
      if (this.calculateProgress.percentage > 50) {
        return 'warning'
      }
      return 'success'
    },
    calculateProgress () {
      if (!this.kase?.expected_return && this.kase?.start) { return }
      let percentage = 100
      let hours = 0
      const now = DateTime.fromISO(DateTime.now())
      const expectedReturn = DateTime.fromISO(this.kase.expected_return)
      const startTime = DateTime.fromISO(this.kase.start)
      const totalDuration = expectedReturn.diff(startTime, 'seconds').seconds
      const timePassed = now.diff(startTime, 'seconds').seconds
      if (timePassed < totalDuration) {
        percentage = Math.round(timePassed / totalDuration * 100)
        hours = Math.round(expectedReturn.diff(startTime, 'hours').hours)
      }
      return {
        percentage: percentage,
        hours: hours
      }
    }
  }
}
</script>
