<template>
  <v-card outlined rounded="lg">
    <v-app-bar
      dense
      color="grey lighten-2"
      elevation="0">
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-text-box</v-icon>
        {{ i18n("jobInstructions") }}
      </v-toolbar-title>
      <v-spacer />
      <v-chip color="warning" class="rounded-lg">{{ kase.case_mkey }}</v-chip>
    </v-app-bar>
    <v-card-text>
      <h3>
        <v-chip class="rounded-lg mb-2">
          <v-icon class="mr-2">mdi-clock</v-icon>
          {{ i18n("dueTime") }}: {{ kase.expected_return | luxon({ output: "tz_med" }) }}
        </v-chip>
      </h3>
      <span v-html="i18n('jobDescriptionDetail')"></span>
      <span v-if="!kase.upload_not_required" v-html="i18n('digitalJobText')"></span>
      <span v-if="kase.upload_not_required" v-html="i18n('physicalJobText')"></span>
      <v-row class="ma-2">
        <h3>{{ i18n("jobStatus") }}:</h3>
        <v-chip small :color="color" class="ml-2 rounded-lg">{{ jobStatus }}</v-chip>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Overview',
  computed: {
    ...mapGetters(['kase']),
    color () {
      if (this.kase.overdue_sent_at) { return 'error' }
      if (this.kase.status === 'IN_PROGRESS') { return 'success' }
      return 'warning'
    },
    jobStatus () {
      if (this.kase.overdue_sent_at) { return this.i18n('jobOverdue') }
      return this.i18n(this.toCamelCase(this.kase.status))
    }
  },
  methods: {
    toCamelCase (string) {
      return string.toLowerCase().replace(/[^a-zA-Z]+(.)/g, (text) => text.toUpperCase()).replaceAll('_', '')
    }
  }
}
</script>
