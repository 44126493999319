<template>
  <v-card
    outlined
    rounded="lg"
    v-if="kase.upload_not_required">
    <v-app-bar
      dense
      color="grey lighten-2"
      elevation="0"
      class="mb-2">
      <v-toolbar-title>
        <v-icon class="mr-2">
          mdi-truck-check
        </v-icon>
        {{ i18n("completedPhysicalWorkSection") }}
      </v-toolbar-title>
      <v-spacer />
      <v-chip color="warning" class="rounded-lg">{{ i18n("physical") }}</v-chip>
    </v-app-bar>
    <v-card-text>
      <v-text-field
        v-model="kase.return_address"
        outlined
        dense
        readonly
        :label="i18n('returnAddress')">
        <template v-slot:append-outer>
          <v-btn
            class="rounded-lg"
            style="top: -8px"
            offset-y
            @click="copyReturnAddress()">
            <v-icon>
              mdi-content-copy
            </v-icon>
            {{ i18n("copy") }}
          </v-btn>
        </template>
      </v-text-field>
      <v-form ref="dispatchForm">
        <v-text-field
          v-model="trackingNumber"
          outlined
          dense
          :rules="[trackingNumberValidation]"
          :label="i18n('trackingNumber')" />
      </v-form>
      <v-row>
        <v-spacer />
        <v-col :cols="smallScreen ? 12 : 6">
          <v-btn
            class="rounded-lg"
            color="success"
            width="100%"
            @click="triggerSave()">
            <v-icon  class="mr-2">
              mdi-check
            </v-icon>
            {{ i18n("save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PhysicalJob',
  props: ['smallScreen'],
  data () {
    return {
      trackingNumber: null
    }
  },
  computed: {
    ...mapGetters(['kase']),
    trackingNumberValidation () {
      if (!this.trackingNumber) { return this.i18n('dispatchErrorMessage') }
      return true
    }
  },
  methods: {
    ...mapActions(['triggerReload']),
    copyReturnAddress () {
      navigator.clipboard.writeText(this.kase.return_address)
    },
    async triggerSave () {
      if (!this.$refs.dispatchForm.validate()) { return }
      this.$axios.post(`/api/v2/outsource/${this.kase.outsource_key}/`, {
        action: 'complete_job',
        tracking_number: this.trackingNumber
      }).then(() => {
        this.triggerReload()
      })
    }
  }
}
</script>
