import Vue from 'vue'
import VueLuxon from 'vue-luxon'
import vuetify from '@/plugins/vuetify'
import VueObserveVisibility from 'vue-observe-visibility'
import axios from '@/api/axios'
import App from './App.vue'
import store from './store'
import i18n from '@/shared/i18n'

Vue.config.productionTip = false
Vue.use(VueLuxon, {
  templates: {
    tz_med: {
      zone: 'local',
      format: 'med'
    },
    tz_date_med: {
      zone: 'local',
      format: 'date_med'
    },
    tz_date_full: {
      zone: 'local',
      format: 'date_full'
    }
  },
  input: {
    zone: 'Australia/Melbourne',
    format: 'iso'
  },
  output: {
    zone: 'Australia/Melbourne',
    format: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  }
})
Vue.use(VueObserveVisibility)

const i18nPlugin = {
  install (Vue) {
    Vue.prototype.i18n = (key) => {
      return i18n(localStorage.locale, key)
    }
  }
}

Vue.use(axios)
Vue.use(i18nPlugin)

new Vue({
  vuetify,
  store,
  i18nPlugin,
  render: h => h(App)
}).$mount('#app')
