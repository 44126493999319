import locales from './Locales'

export default (locale = 'en', key) => {
  if (!locale || !key) { return }
  if (locales[locale][key]) {
    return locales[locale][key]
  } else {
    return key
  }
}
