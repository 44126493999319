<template>
  <div v-if="message.attachments.length > 0">
    <ul style="padding-left: 0; list-style-type: none;">
      <li
        v-for="(attachment, index) in message.attachments"
        :key="index">
        <a @click="viewFile(attachment)">
          <v-icon small>mdi-paperclip</v-icon>
          <small>{{ attachment.file_name.slice(7) }}</small>
        </a>
      </li>
    </ul>
    <FileViewer v-model="file" type="message_attachment" />
  </div>
</template>

<script>
import FileViewer from '@/shared/FileViewer'

export default {
  name: 'Attachments',
  props: ['message'],
  data () {
    return {
      file: null
    }
  },
  components: {
    FileViewer
  },
  methods: {
    viewFile (file) {
      this.file = file.uid
    }
  }
}
</script>
