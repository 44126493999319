<template>
  <v-card outlined rounded="lg">
    <v-app-bar
      dense
      color="grey lighten-2"
      elevation="0">
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-file-download</v-icon>
        {{ i18n("jobFiles") }}
      </v-toolbar-title>
    </v-app-bar>
    <v-card-text>
      <v-list dense>
        <v-list-item
          v-for="file in kase.outsource_sent_files"
          :key="file.id">
          <v-list-item-icon>
            <v-icon>mdi-paperclip</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ file.filename }}
            <small><i>{{ file.added | luxon({ output: "tz_med" }) }}</i></small>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon  @click="viewJobFile(file)">mdi-eye</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <div
        class="text-center mb-5"
        v-if="kase.outsource_sent_files.length === 0">
        {{ i18n("noJobFiles") }}
      </div>
      <FileViewer v-model="jobFile" type="outsource_sent_file" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import FileViewer from '@/shared/FileViewer'

export default {
  name: 'JobFiles',
  components: {
    FileViewer
  },
  data () {
    return {
      jobFile: null
    }
  },
  computed: {
    ...mapGetters(['kase'])
  },
  methods: {
    viewJobFile (file) {
      this.jobFile = file.id
    }
  }
}
</script>
