<template>
  <v-container v-if="filePath">
    <v-row align="center" justify="center" v-if="!loaded">
      <v-progress-circular color="primary" indeterminate size="200">
        Loading 3D File
      </v-progress-circular>
    </v-row>
    <ModelStl
      v-if="isSTLFile"
      :background-color="bgColor"
      :lights="lights"
      :src="filePath"
      :width="width"
      :height="height"
      @on-load="setLoaded" />
    <ModelPly
      v-if="isPLYFile"
      :background-color="bgColor"
      :lights="lights"
      :src="filePath"
      :width="width"
      :height="height"
      @on-load="setLoaded" />
  </v-container>
</template>

<script>
import { ModelPly, ModelStl } from 'vue-3d-model'

export default {
  name: 'ModelViewer',
  props: ['filePath', 'backgroundColor', 'width', 'height'],
  data () {
    return {
      loaded: false,
      lights: [
        {
          type: 'HemisphereLight',
          position: { x: 0, y: 1, z: 0 },
          skyColor: 0xffffff,
          groundColor: 0x806060,
          intensity: 0.5
        },
        {
          type: 'DirectionalLight',
          position: { x: 0, y: -10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        },
        {
          type: 'DirectionalLight',
          position: { x: 0, y: 10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        },
        {
          type: 'DirectionalLight',
          position: { x: 10, y: -10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        },
        {
          type: 'DirectionalLight',
          position: { x: -10, y: 10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        }
      ]
    }
  },
  components: {
    ModelPly,
    ModelStl
  },
  computed: {
    bgColor () {
      if (this.backgroundColor) { return this.backgroundColor }
      return 0xffffff
    },
    isSTLFile () {
      if (!this.filePath) { return false }
      if (this.filePath.toLowerCase().includes('.stl')) { return true }
      return false
    },
    isPLYFile () {
      if (!this.filePath) { return false }
      if (this.filePath.toLowerCase().includes('.ply')) { return true }
      return false
    }
  },
  methods: {
    setLoaded () {
      this.loaded = true
    }
  }
}
</script>
